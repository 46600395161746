import { Tooltip } from 'bootstrap';
import inlineForm from './modules/inlineForm';
import productVariant from './modules/productVariant';
import checkout from './modules/checkout';
// eslint-disable-next-line import/first
import GLightbox from 'glightbox';
import addressBookFill from './modules/addressBookFill';
import provinceForm from './modules/provinceForm';
import modal from './modules/modal';
// import feather from 'feather-icons';
import './pages/homepage';

// eslint-disable-next-line no-undef
[].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
  .map(tooltipTriggerEl => new Tooltip(tooltipTriggerEl, { html: true }));

// feather.replace();

(() => {
  // eslint-disable-next-line no-undef
  document.gLightbox = GLightbox({
    selector: '.glightbox',
  });
  inlineForm();
  productVariant();
  checkout();
  addressBookFill();
  provinceForm();
  modal();
})();
