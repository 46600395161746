// core version + navigation, pagination modules:
import Swiper from 'swiper';
import {Navigation, Pagination} from "swiper/modules";

// import Swiper and modules styles
// import 'swiper/swiper.scss';


// init Swiper:
const swiper = new Swiper('.swiper', {
  // Optional parameters
  loop: true,
  modules: [Navigation, Pagination],

  // If we need pagination
  pagination: {
    el: '.swiper-pagination',
  },

  // Navigation arrows
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  autoplay: {
    delay: 3000,
    disableOnInteraction: false,
  },
});
