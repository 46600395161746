function updateContainers(btn) {
  const containerListGrid = document.getElementById("container-list-grid");
  const containerCarProducts = document.querySelectorAll(".cardproduct");
  const carProducts = document.querySelectorAll(".cardproduct2");
  const cardImages = document.querySelectorAll(".cardimg"); // Assurez-vous que la classe est correcte
  const descriptions = document.querySelectorAll('.productdescription');
  const prices = document.querySelectorAll('.cardprice');
  const prices2 = document.querySelectorAll('.cardprice2');
  const productsright = document.querySelectorAll('.productright');

    if (btn.classList.contains('active')) {
      containerListGrid.classList.add("list-container-product");
      containerListGrid.classList.remove("wrap-container-product");
      containerCarProducts.forEach(container => {
        container.classList.add("container-car-product-list");
        container.classList.remove("container-car-product");
      });
      carProducts.forEach(card => {
        card.classList.add("card-product2-list");
        card.classList.remove("card-product2");
      });
      cardImages.forEach(img => {
        img.classList.add("card-img-list2"); // Ajouter la nouvelle classe
        img.classList.remove("card-img-grid2"); // Supprimer l'ancienne classe
      });
       descriptions.forEach(description => {
        description.classList.add("card-product-description-visible");
        description.classList.remove("card-product-description2");
      });
      prices.forEach(price => {
      price.classList.add("card-product-pricing2");
      price.classList.remove("card-product-pricing-none");
      });
      prices2.forEach(price2 => {
      price2.classList.add("card-product-pricing-none");
      price2.classList.remove("card-product-pricing");
      });
      productsright.forEach(productright => {
      productright.classList.add("product-list-right");
      });
    } else {
      containerListGrid.classList.remove("list-container-product");
      containerListGrid.classList.add("wrap-container-product");
      containerCarProducts.forEach(container => {
        container.classList.add("container-car-product");
        container.classList.remove("container-car-product-list");
      });
      carProducts.forEach(card => {
        card.classList.add("card-product2");
        card.classList.remove("card-product2-list");
      });
      cardImages.forEach(img => {
        img.classList.add("card-img-grid2"); // Remettre l'ancienne classe
        img.classList.remove("card-img-list2"); // Supprimer la nouvelle classe
      });
      descriptions.forEach(description => {
           description.classList.add("card-product-description2");
        description.classList.remove("card-product-description-visible");
      });
      prices.forEach(price => {
      price.classList.add("card-product-pricing-none");
      price.classList.remove("card-product-pricing2");
      });
      prices2.forEach(price2 => {
      price2.classList.add("card-product-pricing");
      price2.classList.remove("card-product-pricing-none");
      });
      productsright.forEach(productright => {
      productright.classList.remove("product-list-right");
      });
    }
  }


  // Fonction pour gérer la bascule du bouton, la mise à jour des divs, et l'animation.
  function toggleAndAnimate() {
    this.classList.add('animation');
    this.classList.toggle('active');
    updateContainers(this);

    // Écouteur pour l'animationend pour retirer la classe 'animation' et cloner le bouton.
    this.addEventListener('animationend', function() {
      this.classList.remove('animation');

      // Clonage du bouton pour "réinitialiser" l'animation.
      let newElem = this.cloneNode(true);
      this.parentNode.replaceChild(newElem, this);

      // Réattacher les écouteurs d'événements au nouveau bouton.
      newElem.addEventListener('click', toggleAndAnimate);
    }, { once: true });
  }

  // Attache les écouteurs d'événements une fois que le DOM est chargé.
  document.addEventListener("DOMContentLoaded", function() {
    document.querySelectorAll('.grid-list').forEach(button => {
      button.addEventListener('click', toggleAndAnimate);
    });
  });
